/* google font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&family=VT323&display=swap');

/* layout */
:root {
  --primary: #1aac83;
  --error: #e7195a;
}
body {
  background: #f1f1f1;
  margin: 0;
  font-family: "Poppins";
}
header {
  background: #fff;
  width:100%;
  position: fixed;
  top: 0;
  z-index:1000;
}
header .navcontainer {
  height: 90px;
  margin: 0;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
}

header a {
  color: #333;
  text-decoration: none;
}

.sideandpagecontainer {
  display: flex;
  width: 100%;
  height:100%;
  margin-top:110px;
}

.pages {
  padding: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  flex-grow:1;
  overflow-y: auto;
}

/* homepage */
.home {
  display: flex;
  flex-direction:column;
  width:100%;
  height:100%;
}
.entity-details {
  background: #fff;
  border-radius: 4px;
  margin: 20px 40px;
  padding: 20px;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.05);
  
}
.entity-details h4 {
  margin: 0 0 10px 0;
  font-size: 1.2em;
  color: var(--primary);
}
.entity-details p {
  margin: 0;
  font-size: 0.9em;
  color: #555;
}
.entity-details span {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  background: #f1f1f1;
  padding: 6px;
  border-radius: 50%;
  color: #333;
}

/* new startup form */
label, input {
  display: block;
}
input {
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

form h2 {
  color: var(--primary);
}

.upload {
  margin-top: 40px;
}

form button {
  background: var(--primary);
  border: 1px solid #f1f1f1;
  color: #fff;
  padding: 10px;
  font-family: "Poppins";
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid var(--primary);
  margin-top: 20px;
}
form button:hover {
  background: #fff;
  border: 1px solid var(--primary);
  color: var(--primary);
  transition: all 0.2s;

}

.add {
  display: flex;
  gap:10px;
}


.errorMessage {
  padding: 10px;
  background: #ffefef;
  border: 1px solid var(--error);
  color: var(--error);
  border-radius: 4px;
}

.add .errorMessage {
  font-size:13px;
  margin: 20px 0 0 0;
}

.add .success {
  font-size:12px;
  margin: 20px 0 0 0;
}

div.success {
  padding: 10px;
  background: #ffefef;
  border: 1px solid var(--primary);
  color: var(--primary);
  border-radius: 4px;
  font-size: 13px;
}


.error {
  border: 1px solid var(--error);
}

/* navbar */
nav {
  display: flex;
  align-items: center;
  
}

nav button {
  background: #fff;
  color: var(--primary);
  border: 2px solid var(--primary);
  padding: 6px 10px;
  border-radius: 4px;
  font-family: "Poppins";
  cursor: pointer;
  font-size: 1em;
  margin-left: 10px;
}

/*sidebar*/
.sidebarcontainer {
  width:20%;
}


.sidebar {
  position: fixed;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  padding: 40px 0 0 0;
  background-color: #fff; 
  color: var(--primary); 
  border-right: 1px solid #e0e0e0;
  width:20%;
}


.ul-container {
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  width:100%;
}

.navlink {
  text-decoration: none;
  width: 80%;
  padding: 4px 0 4px 10px;
  border-radius: 10px; 
}

.navlink:hover {
  background: #f0f0f0;
}


/* auth form */
form.login {
  width: 400px;
  margin: 40px auto;
  padding: 20px;
  background: #fff;
  border-radius: 4px;
}

form.login button {
  margin-bottom: 10px;
}

.passwordField {
  position: relative;
  display: flex;
  align-items: center;
}

.passwordField input {
  width: 100%;
}

.eyeIcon {
  position: absolute;
  right: 10px;
  cursor: pointer;
  margin-bottom:8px;
}

/*startup form*/

form.startupform {
  margin: 20px;
  padding: 30px 100px;
  background: #fff;
  border-radius: 4px;
  width: 50%;
}

form.startupform button {
  margin-bottom:15px;
}

form.startupform .errorMessage {
  width: 260px;
}

/* thank you message*/
div.thanks {
  width:100%;
  margin-top: 50px;
  display: flex;
  justify-content:center;
  align-items:center;
  flex-direction:column;
}
.thanks .thanks-text {
  text-align: center;
}
h1.thanks {
  color: var(--primary);
  margin: 5px;
}

/*startups components*/
.flex {
  display:flex;
  justify-content: space-between;
}

.flex div {
  padding-right: 10px;
}


/*Drop down menu*/

.dropdown {
  width:400px;
  user-select:none;
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
  margin-top: 10px;
  width:100%;
}

.dropdown-btn {
  height:20px;
  padding: 10px;
  background: #fff;
  box-shadow: 3px 3px 10px 6px rgba(0,0,0,0.06);
  border-radius:4px;
  color: #333;
  display:flex;
  align-items:center;
  justify-content:space-between;
  cursor: pointer;

}

.dropdown-content {
  position: absolute;
  top: 110%;
  left: 0;
  max-height: 200px;
  overflow-y: auto;
  padding: 10px;
  background: #fff;
  box-shadow: 3px 3px 10px 6px rgba(0,0,0,0.06);
  color: #333;
  width:95%;
  z-index: 1000;
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
  transition: all 0.2s;
} 

.dropdown.active .dropdown-content {
  display: block;
}

.dropdown .dropdown-item:hover {
  background: #f4f4f4;
}

.dropdown .selected {
  background: #1aac83; 
  color: #fff; 
}

.dropdown .selected:hover {
  background: #1aac83; 
  color: #fff; 
}

.dropdown .selected-options {
  font-size: 10.5px;
}


.caret-down {
  width: 0; 
  height: 0; 
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid black;
  margin-left: 10px;
}

/*startup preview*/
.preview-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.trashcontainer {
  border-radius: 50%;
  display:flex;
  justify-content: center;
  align-items: center;
  width:30px;
  height:30px;
}
.trashcontainer:hover {
  background: #f0f0f0;
}

.caret-sides {
  color: var(--primary);
  font-size: 13px;
  margin-left: 6px
}
.caret-sides:first-child {
  margin-right: 6px;
}

.pagenavigate {
  width: 140px;
  display: flex;
  justify-content: space-between;
}

.content {
  height: 100%;
}

.no-underline {
  text-decoration:none;
}

/*feedback*/
.feedback-text {
  height : 200px;
}

/*startup profile*/
.inpagelink {
  text-decoration: none;
  color: var(--primary);
}

.profilecontainer {
  width: 60%;
  margin: auto;
  margin-top:50px;
}

.headercontainer {
  width:230px;
  height:180px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sdgcontainer {
  margin: 50px 0 50px 0;
  display:flex;
  align-items: flex-end;
  justify-content: center;
  gap:5px;

}

.primarySDG {
  width:150px;
}

.otherSDG {
  width:120px;
}

.imgInsideContainer {
  max-width:100%;
  max-height:100%;
  width:auto;
  height:auto;
}

.profilesubcontainer {
  display: flex;
  justify-content: space-between;
  max-width: 100%;
}

.profilesubcontainer:first-child {
  width: 90%;
  margin-bottom: 40px;
}

.financialblock:first-child{
  width:50%;
  margin-right:10px;
}

.financialblock:nth-child(2){
  width:40%;
}
.financialblock:nth-child(3){
  width:30%;
}


.smaller {
  width:90%;
}

.profilecontainer h1 {
  margin: auto;
}
.profilesubcontainer h2  {
  color: var(--primary);
}
.profileblock {
  width: 30%;
}

#investorprofile {
  width:45%
}

.founders {
  max-width: 200px;
  width:100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  gap: 10px;
}

.center {
  display:flex;
  justify-content: center;
}


textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  margin: 10px 0;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #ddd;
  font-size: 16px;
  resize: none;
}

.files {
  display: flex;
  gap: 30px;
}
.filecontainer {
  text-align: center;
}
.filecontainer p{
  margin: 0 0 5px 0;
}

/*file upload*/

.file-upload {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 420px;
}

/* Define the default dropzone style */
.dropzone {
  border: 1px dashed #cccccc;
  border-radius: 4px;
  background: #f7f7f7;
  padding: 15px 10px 15px 10px;
  text-align: center;
  cursor: pointer;
  width: 60%;
  margin-left: 20px;
  margin-bottom: 10px;
  height: 20px;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* Define the error style */
.dropzone.error {
  border: 1px solid var(--error);
}

/* Define the active style (when user is dragging) */
.dropzone.active {
  border: 1px dashed blue;
  background-color: rgb(217, 241, 249);
}

/* Define hover style (for hover effect) */
.dropzone:not(.active):hover {
  border: 1px dashed blue;
  background-color: rgb(217, 241, 249);
}

.dropzone .currentFile {
  color: var(--primary);
  font-weight: bold;
}

.dropzone p {
  margin: 0;
}

.green {
  color: var(--primary);
}
.red {
  color: var(--error);
}

/*manage access*/
.managecontainer {
  padding-left: 10px;
  width:90%;
  height:100%;
  display:flex;
  flex-direction:column;
  align-items: center;
}

.managetitle {
  width:100%;
}
.access-forms {
  display: flex;
  gap: 100px;
  width: 100%;
}
form.signup {
  width: 50%;
}
.user-details-container {
  display: flex;
  width: 100%;
  gap: 100px;
}
.user-details {
  margin-top: 50px;
  width: 50%;
}
.user-listing {
  display: flex;
  align-items: center;
  height: 25px;
  background: #fff;  
  border: 1px solid #ddd;
  border-radius: 4px;  
}
.listing-header {
  display: flex;
  align-items: center;
  height: 25px;   
}
.listing-column {
  display:flex;
  align-items:center;
  height:100%;
}

.listing-column:first-child {
  padding-left: 8px;
  width:25%;
}
#listing-email {
  width: 30%;
}
#listing-registered {
  justify-content: center;
  width:15%;
}
#listing-createdat {
  padding-left:10px;
  width:20%;
}

.listing-column:last-child {
  justify-content: flex-end;
  flex:1;
  padding-right: 2px;
}

.user-details p {
  margin:0;
  font-size: 10px; 
  white-space: nowrap;
  overflow: hidden; 
  text-overflow: ellipsis;
}
.listing-column .trashcontainer {
  width:22px;
  height:22px;
}
.listing-trash {
  font-size: 13px;
}

/*random general styles*/

.full-width {
  width: 95%;
}

.required {
  color: red;
  font-size:14px;
}

.mandatory {
  font-size:11px;
  margin-bottom: 20px;
}
